var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-full max-w-lg" }, [
    _c(
      "form",
      {
        staticClass: "mb-4",
        class: { loading: _vm.loading },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.submit($event)
          }
        }
      },
      [
        _c(
          "div",
          { staticClass: "mb-4" },
          [
            _c("base-label", { attrs: { name: "username" } }, [
              _vm._v("\n        E-mail\n      ")
            ]),
            _c("secondary-input", {
              directives: [{ name: "focus", rawName: "v-focus" }],
              attrs: { id: "email", placeholder: "E-mail" },
              model: {
                value: _vm.email,
                callback: function($$v) {
                  _vm.email = $$v
                },
                expression: "email"
              }
            }),
            _vm.emailError
              ? _c(
                  "div",
                  {
                    staticClass:
                      "flex flex-row mt-2 p-2 bg-white border border-red-500 rounded"
                  },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "mr-4 ml-2 text-lg self-center text-red-500",
                      attrs: { icon: "exclamation-circle" }
                    }),
                    _c("p", { staticClass: "self-center text-base" }, [
                      _vm._v(
                        "\n          " + _vm._s(_vm.emailError) + "\n        "
                      )
                    ])
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mb-6" },
          [
            _c("base-label", { attrs: { name: "password" } }, [
              _vm._v("\n        Password\n      ")
            ]),
            _c("secondary-input", {
              attrs: {
                id: "password",
                placeholder: "********",
                type: "password"
              },
              model: {
                value: _vm.password,
                callback: function($$v) {
                  _vm.password = $$v
                },
                expression: "password"
              }
            }),
            _vm.passwordError
              ? _c(
                  "div",
                  {
                    staticClass:
                      "flex flex-row mt-2 p-2 bg-white border border-red-500 rounded"
                  },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "mr-4 ml-2 text-lg self-center text-red-500",
                      attrs: { icon: "exclamation-circle" }
                    }),
                    _c("p", { staticClass: "self-center text-base" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.passwordError) +
                          "\n        "
                      )
                    ])
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        ),
        _vm.error ||
        (_vm.unauthorized && (!_vm.passwordError && !_vm.emailError))
          ? _c(
              "div",
              {
                staticClass:
                  "flex flex-row mb-6 p-3 bg-white border border-red-500 rounded"
              },
              [
                _c("font-awesome-icon", {
                  staticClass: "mr-4 ml-2 text-lg self-center text-red-500",
                  attrs: { icon: "exclamation-circle" }
                }),
                _c("p", { staticClass: "self-center text-base" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.error ||
                          "You have been logged out. Please log in again."
                      ) +
                      "\n      "
                  )
                ])
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "flex flex-wrap items-center justify-between" },
          [
            _c(
              "primary-button",
              { staticClass: "w-full mb-2" },
              [
                _vm._v("\n        Sign in "),
                _c("base-spinner", { attrs: { loading: _vm.loading } })
              ],
              1
            )
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }