<template>
  <div class="flex h-full">
    <div class="flex flex-col justify-center items-center w-full p-8">
      <img
        class="w-40"
        :src="require('@/assets/firstvet-logo-color.png')"
      >
      <h1 class="text-2xl font-semibold mb-2 mt-2 pb-2 text-center">
        Sign in
      </h1>
      <login-form />
    </div>
  </div>
</template>
<script>
import LoginForm from '@/components/LoginForm';

export default {
  components: {
    LoginForm,
  },
};
</script>
