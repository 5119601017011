var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex h-full" }, [
    _c(
      "div",
      { staticClass: "flex flex-col justify-center items-center w-full p-8" },
      [
        _c("img", {
          staticClass: "w-40",
          attrs: { src: require("@/assets/firstvet-logo-color.png") }
        }),
        _c(
          "h1",
          { staticClass: "text-2xl font-semibold mb-2 mt-2 pb-2 text-center" },
          [_vm._v("\n      Sign in\n    ")]
        ),
        _c("login-form")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }