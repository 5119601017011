<template>
  <div class="w-full max-w-lg">
    <form
      class="mb-4"
      :class="{'loading': loading}"
      @submit.prevent="submit"
    >
      <div class="mb-4">
        <base-label name="username">
          E-mail
        </base-label>
        <secondary-input
          id="email"
          v-model="email"
          v-focus 
          placeholder="E-mail"
        />
        <div
          v-if="emailError"
          class="flex flex-row mt-2 p-2 bg-white border border-red-500 rounded"
        >
          <font-awesome-icon
            icon="exclamation-circle"
            class="mr-4 ml-2 text-lg self-center text-red-500"
          />
          <p class="self-center text-base">
            {{ emailError }}
          </p>
        </div>
      </div>
   
      <div class="mb-6">
        <base-label name="password">
          Password
        </base-label>
        <secondary-input 
          id="password"
          v-model="password" 
          placeholder="********"
          type="password"
        />
        <div
          v-if="passwordError"
          class="flex flex-row mt-2 p-2 bg-white border border-red-500 rounded"
        >
          <font-awesome-icon
            icon="exclamation-circle"
            class="mr-4 ml-2 text-lg self-center text-red-500"
          />
          <p class="self-center text-base">
            {{ passwordError }}
          </p>
        </div>
      </div>

      <!-- If user is logged out beacuse of an invalid token, or 401 error, show error message (unauthorized) -->
      <div
        v-if="error || unauthorized && (!passwordError && !emailError)"
        class="flex flex-row mb-6 p-3 bg-white border border-red-500 rounded"
      >
        <font-awesome-icon
          icon="exclamation-circle"
          class="mr-4 ml-2 text-lg self-center text-red-500"
        />
        <p class="self-center text-base">
          {{ error || 'You have been logged out. Please log in again.' }}
        </p>
      </div>
      
      <div class="flex flex-wrap items-center justify-between">
        <primary-button
          class="w-full mb-2"
        >
          Sign in <base-spinner :loading="loading" />
        </primary-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      email: '',
      password: '',
      loading: false,
      emailError: '',
      passwordError: '',
      error: '',
    };
  },
  computed: {
    ...mapGetters('auth', {
      unauthorized: 'getUnauthorized',
    }),
  },
  methods: {
    ...mapActions('auth', ['login', 'setUnauthorized']),
    validateForm() {
      this.emailError = '';
      this.passwordError = '';

      if (this.email === '')
        this.emailError = 'The email field cannot be empty';
      if (this.password === '')
        this.passwordError = 'The password field cannot be empty.';

      return !this.passwordError && !this.emailError;
    },

    submit() {
      this.error = '';
      if (!this.validateForm()) return;
      this.loading = true;
      this.setUnauthorized(false);
      this.login({ email: this.email, password: this.password })
        .then(res => {
          console.log(res);
          this.$router.push({ name: 'home' });
        })
        .catch(error => {
          console.log({ error });
          if (error.response) {
            this.error = error.response.data.message;
          } else {
            this.error = error.data.message;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
